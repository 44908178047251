import React from "react";
import { Link } from "react-router-dom";

import { TitleBlock, AccentLineMain, FooterBlock } from "../../App.styles";

import CustomButton from "../../components/custom-button/custom-button.component";

import { Paragraph, Wrapper } from "./academiespage.styles";

const AcademiesPage = () => {
  return (
    <Wrapper>
      <TitleBlock>
        <div>
          <h1 className="newSiteHeading">Academies</h1>
        </div>
        <div>
          <p>
            ABC Greater Baltimore offers a range of academies designed to
            advance skills in key areas of the construction industry, including
            leadership, project management, financial administration, and
            business development. These programs provide comprehensive training
            to help professionals excel in planning, budgeting, compliance, and
            building strong industry relationships. Whether you’re a crew
            leader, project manager, or business development professional, these
            academies equip you with the tools to succeed and grow in your
            career.
          </p>
          <h3>Other Management Classes:</h3>
          <Link to="blueprint-reading">Blueprint Reading</Link>
          <br />
          <Link to="operations">Operations</Link>
          <br />
          <Link to="legal-regulatory">Legal & Regulatory</Link>
          <br />
          <br />
        </div>
      </TitleBlock>
      <AccentLineMain />
      <Paragraph>
        <h3>Superintendents Academy</h3>
        <p>This is a 16 hour series of courses that will cover:</p>
        <ul>
          <li>PLANNING & SCHEDULING</li>
          <li>PROJECT COST CONTROL</li>
          <li>CONSTRUCTION PRODUCTIVITY</li>
          <li>CREW LEADERSHIP</li>
          <li>PROJECT CLOSE OUT</li>
        </ul>

        <Link to="https://events.abcbaltimore.org/events/list/?tribe-bar-search=%22Superintendents+Academy%22">
          <CustomButton newSiteButton>Find Courses</CustomButton>
        </Link>
      </Paragraph>
      <Paragraph className="blue">
        <h3>Project Management Academy</h3>
        <p>This is a 15 hour series of courses that will cover:</p>
        <ul>
          <li>PRECONSTRUCTION & OPERATIONS BUDGET MANAGEMENT</li>
          <li>PLANNING & SCHEDULING</li>
          <li>CONSTRUCTION CLAIMS</li>
          <li>PROJECT CLOSE OUT</li>
        </ul>

        <Link to="https://events.abcbaltimore.org/events/list/?tribe-bar-search=%22project+management+academy%22">
          <CustomButton newSiteButton>Find Courses</CustomButton>
        </Link>
      </Paragraph>
      <Paragraph>
        <h3>Construction Administration & Accounting Academy</h3>
        <p>This is a 12 hour series of courses that will cover:</p>
        <ul>
          <li>FINANCIAL REPORTS USED IN CONSTRUCTION</li>
          <li>FRAUD PREVENTION</li>
          <li>CONTRACTOR COMPLIANCE & RECORDKEEPING</li>
          <li>JOB COSTING</li>
          <li>RECEIVABLES & CASH MANAGEMENT</li>
        </ul>

        <Link to="https://events.abcbaltimore.org/events/list/?tribe-bar-search=Construction+Administration">
          <CustomButton newSiteButton>Find Courses</CustomButton>
        </Link>
      </Paragraph>
      <Paragraph className="blue">
        <h3>Relationship & Business Development Academy</h3>
        <p>
          Who should come: Business Development professionals from commercial
          construction primes doing business with private and public owners;
          sub-contractors performing commercial work; and members with services
          related to the commercial building industry.
        </p>
        <ul>
          <li>DEVELOPING THE “RIGHT” RELATIONSHIPS AND OPPORTUNITIES</li>
          <li>CONDUCTING PROFESSIONAL MEETINGS THAT PRODUCE RESULTS</li>
          <li>BUILDING A RELATIONSHIP AND VALUE OVER TIME</li>
        </ul>

        <Link to="https://events.abcbaltimore.org/events/list/?tribe-bar-search=Relationship+Academy">
          <CustomButton newSiteButton>Find Courses</CustomButton>
        </Link>
      </Paragraph>
      <Paragraph>
        <h3>Crew Leadership Training</h3>
        <p>
          While this course has been designed to assist the recently promoted
          crew leader, it is beneficial for anyone in management. The course
          covers basic leadership skills and explains different leadership
          styles, communication, delegating, and problem solving. Job-site
          safety and the crew leader’s role in safety are also discussed. This
          edition goes into detail on project planning, scheduling, and
          estimating with new performance tasks to assist the learning process.
        </p>
        <Link to="https://events.abcbaltimore.org/events/list/?tribe-bar-search=Crew+Leadership+Training">
          <CustomButton newSiteButton>Find Courses</CustomButton>
        </Link>
      </Paragraph>
      <FooterBlock>
        <h2 className="newSiteHeading">
          Grow your career with us. Be more with ABC Baltimore.
        </h2>
      </FooterBlock>
    </Wrapper>
  );
};

export default AcademiesPage;
